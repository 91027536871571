/* GRALES */

body {
  font-family: "Hermann";
  overflow-x: hidden;


}
.App {
  text-align: center;
/* esto creo que no juega muchio, pero lo veo. */
}  


/* ESTILOS NAV */

nav {
  position: fixed;
  width: 100%;
  background-color: #222;
  /* Color más oscuro */
  color: white;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.5s ease;
}

nav.scrolled {
  background-color: rgba(51, 51, 51, 0.904);
  /* Transparencia al hacer scroll */
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  height: 60px;
  /* ancho nav*/

}

.nav-content ul li a:hover  {
  color: #369d6d;
  /* Cambia el color del lINK al pasar el mouse */
  text-decoration: none;
  /* Quitar subrayado del enlace */
}



/* LOGO NAV */

.logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
 
 }

.logo-nav {
  height: 50px;
  margin-top: 10px;
  margin-right: -10px;
  margin-bottom: 10px;
  
}




.description {
  text-decoration: none !important;
}


.description1 {
  font-family: 'Plus Jakarta Sans';
  font-style: medium;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  

}

.description2 {
  font-family: 'Hermann';
  font-weight: 600;
  font-style: italic;
  font-size: 1.1rem;
  color: #fff;
  
}


.seccion-home {
  /*links del nav*/
  font-family: 'Plus Jakarta Sans';
  font-size: 1rem;
}



.seccion-home .nav-links {
  cursor: pointer;
}


/* ESTILOS HEADER 1*/

#header {
  width: 100%;
  /* Asegura que el header ocupe todo el ancho */
  overflow: hidden;

}

.header-bg {
  width: 100%;
  position: relative;
}



.header-image {
  width: 100%;
  height: 18rem;
  /*Este fue un agregado para que al achicar pantalla , el titulo no se esconda*/
  object-fit: cover;
  object-position: bottom;
  /*FOTO PARTE DE ABAJO*/
  opacity: 0.7;
}



.header-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -48%);
  /*aca ajustamos la caja trasnparente*/
  text-align: center;
}

.header-logo-container {
  /* CONTENEDOR TRANSPARENTE DEL LOGO HEADER */
  display: inline-block;
  background: rgba(0, 0, 0, 0.737);
  border-radius: 5px;
  padding: 3px 30px;
  margin-top: 70px;

}


.header-logo {
  width: 180px;
  z-index: 2;
}


#header p.subtitle {
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  animation: slideInRight 1.8s ease-in-out;
  color: white;
  margin-top: -10px;
  /*  distancia entre logo y subtítulo */
  margin-left: 20px;
}

.subtitle1 {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  color: #fff;
}

.subtitle2 {
  font-family: 'Hermann';
  font-weight: 600;
  font-style: italic;
  font-size: 1rem;
}



/* QUERYS PARA HEADER: */

/* Estilos para CELUS CHICOS */
@media (min-width: 375px) {

  .header-logo {
    width: 250px;
    z-index: 2;
  }

  .subtitle1 {
    font-size: 0.9rem;
  }

  .subtitle2 {
    font-size: 1rem;
  }

  .header-image {
    height: 23rem;
  }

  .header-logo-container {
    margin-top: 60px;
    padding: 3px 16px;
  }

  #header p.subtitle {
    margin-left: 32px;
  }

  .logo-nav {
    height: 50px;
  }

  .nav-content {
    padding: 0px 8px;

  }



}

/* Estilos para tablets */
@media (min-width: 768px) {
  .header-logo {
    width: 350px;
  }

  .subtitle1 {
    font-size: 1rem;
  }

  .subtitle2 {
    font-size: 1.2rem;
  }

  .header-image {
    height: 30rem;
  }

  .header-logo-container {
    margin-top: 80px;
    padding: 5px 20px;
  }

  #header p.subtitle {
    margin-left: 40px;
  }

  .logo-nav {
    height: 60px;
  }

  .nav-content {
    height: 70px;
  }
}

/* Estilos para notebooks y pantallas grandes */
@media (min-width: 1024px) {
  .header-logo {
    width: 450px;
  }

  .subtitle1 {
    font-size: 1.2rem;
  }

  .subtitle2 {
    font-size: 1.4rem;
  }

  .header-image {
    height: 35rem;
  }

  .header-logo-container {
    margin-top: 100px;
    padding: 8px 24px;
  }

  #header p.subtitle {
    margin-left: 50px;
  }

  .logo-nav {
    height: 60px;
  }

  .nav-content {
    height: 70px;
  }
}



/* Estilos para ocultar las palabras "Gobierno Abierto" en pantallas pequeñas */
@media (max-width: 800px) {

  .description1,
  .description2 {
    display: none;
  }
}

@keyframes slideInRight {

  /*aparicion del subtitulo izq a derecha*/
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}






/* MENU O ALFAJOR NAV */

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

@media (max-width: 1169px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 10;
}

nav ul li {
  margin: 0 25px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  padding: 15px 0;
  display: block;
}



/* ESTILOS HOME */

#home {
  width: 100%;
  background-color: #205c40;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* (tamanio back) */
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  padding-bottom: 420px;
  margin-top: -20px;

}

#home p {
  font-family: 'plus jakarta sans';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
}

.icons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 90%;
  height: 0px;
  /* largo del back */

  box-sizing: border-box;
  cursor: pointer;
  gap: 0.5rem;
  padding: 35px 0px 35px 0px;

}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3px;
  border: 1.5px solid #309362
    /* #8AD5B0 */
  ;
  /*BORDES CARDS*/
  transition: all 0.3s ease;
  min-height: 100px;
  box-sizing: border-box;
  font-size: 2rem;
  /*  background-color: */
  /* rgba(0, 0, 0, 0.23); */
  /*oscuro #183F2C; */
  /* mas oscuro #122A1E;  */
  /*fondos secciones*/
  background-image: url('../public/img/tramas10.jpeg');
  /*TRAMA*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-radius: 10px;

}



.icon p {
  /*titulo secciones home*/
  margin-top: 20px;
  color: #ffffff;
  font-weight: bold;

}


.icon:hover {
  border: 1.5px solid #309362
    /*  #F76B53 */
  ;
  transform:
    /* skewX(10deg)  */
    /* rotate(10deg); */
    translateY(-10px);
  /*rotate(10deg);*/
  box-shadow: 0px 0 25px 0 rgba(198, 197, 196, 0.267);

}

/* Iconos-ilustraciones svg */
.svg-icon {
  width: 68px;
  margin-bottom: -28px;

}

/* Agrega un contenedor para manejar la posición del ícono */
.icon-container {
  position: relative;
  height: 100vh;
  /* Ocupa el 100% de la altura del viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
  /* Asegura espacio debajo del ícono para el footer */
}




/* QUERIS PARA DIVIDIR LAS TARJETAS EN DIFERENTES PANTALLAS.*/


/* Estilos para tablets */

@media (min-width: 500px) {

  .icons {
    width: 85%;
    height: 100%;
  }

  #home {
    padding-bottom: 15px;
  }
}

@media (min-width: 720px) {

  .icons {
    grid-template-columns: repeat(4, 1fr);
    width: 96%;
    height: 520px;
    padding: 60px 0px 35px 0px;
  }

  #home {
    padding-bottom: 30px;
  }

  #home p {
    font-size: 0.9rem;
  }

  .svg-icon {
    width: 96px;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .icon {
    padding: 30px 10px;
    min-height: 190px;
    font-size: 3rem;
  }

  .icons {
    grid-template-columns: repeat(4, 1fr);
    width: 90%;
    padding: 35px 0px 35px 0px;

  }

  #home {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  #home p {
    font-size: 1rem;
    font-weight: 500;
  }

  .icon p {
    margin-top: 26px;
  }

  .svg-icon {
    width: 90px;
    margin-bottom: 2px;
  }

}


@media (min-width: 1024px) {
  .icon {
    padding: 30px 10px;
    min-height: 190px;
    font-size: 3rem;
  }

  .icons {
    grid-template-columns: repeat(4, 1fr);
    width: 90%;
    padding: 35px 0px 35px 0px;

  }

  #home {
    padding-bottom: 40px;
    padding-top: 35px;
  }

  #home p {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .icon p {
    margin-top: 26px;
  }

  .svg-icon {
    width: 120px;
    margin-bottom: -2px;
  }

}

@media (min-width: 1280px) {
  .icon {
    padding: 30px 10px;
    min-height: 280px;
    font-size: 3rem;
  }

  .icons {
    grid-template-columns: repeat(4, 1fr);
    width: 90%;
    padding: 60px 0px 240px 0px;

  }

  #home {
    padding-bottom: 200px;
  }

  #home p {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .icon p {
    margin-top: 26px;
  }

  .svg-icon {
    width: 155px;
    margin-bottom: 15px;
  }

}



@keyframes zoomIn {

  /*zoom in del logo , ir agregando de necesitar*/
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.header-logo.zoom-in {
  animation: zoomIn 0.9s ease-in-out;
}

.header-logo-container.zoom-in {
  animation: zoomIn 0.9s ease-in-out;
}

@keyframes despliegue1 {

  /* secciones de arriba hacia abajo */
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}



.icons {
  animation: despliegue1 2.3s ease forwards;
}




/* ESTILOS SECCIONES */

/* Primer armado, para tamaño 360x640*/


.container {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}



.seccion-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -20px;

}

.texto-seccion {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: justify;

}







/*SEPARADOR (de seccione)*/

.separador {
  height: 30px;
  /* Altura fija del separador */
  background-image: url('../public/img/tramaSeparador.jpeg');
  /*colocar asi las imagenes: public...*/
  background-size: cover;
  /* Ajusta la imagen de fondo al tamaño del contenedor */
  background-position: center;
  /* Centra la imagen de fondo */
  position: relative;
  /* Posición relativa para permitir la animación */
  overflow: hidden;
  /* Oculta el desbordamiento de la imagen */
  margin: -20px 0;
  /* top y right*/

}

/* En construccion */


/* caja contenedora -en construc */

.outer-container {
  width: 80vw; /* Ajusta el tamaño del cuadrado */
  height: 70vw; /* Mantiene la relación cuadrada */
  max-width: 50rem; /* Tamaño máximo del cuadrado */
  max-height: 50rem; /* Tamaño máximo del cuadrado */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Centra el contenedor en la página */
  background-color: white; /* Fondo blanco */
  /* border: 4px solid #205c40;  /* Borde verde */
  border-radius: 10px; /* Bordes redondeados */
  position: relative; /* Asegura que el contenido esté posicionado en relación al contenedor */
  overflow: hidden; /* Oculta el desbordamiento */

}


/* Loading text.css */
.container-construct {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
 
}

/* Estilo del texto */
.text-construct {
  font-size: clamp(1.6rem, 4vw, 2rem);
  color: #205c40;
  font-family: 'monospace';
  font-weight: 500;
  text-align: center;
  padding-bottom: 2rem;
  white-space: nowrap;
  position: relative;
}

/* icono en construccion */
.icon-construc {
  width: 70vw;
  max-width: 75rem;
  height: auto;
  position: relative;

}

/* Estilo del contenedor de la lluvia Matrix */
.matrix-rain-container {
  position: absolute;
  top: -20%;
  left: 0;
  width: 100%;
  height: 250%;
  pointer-events: none; /* Evita la interacción con la lluvia */
  z-index: 2; /* Sobre el fondo, pero detrás del texto e ícono */
}

/* Estilo de los caracteres de la lluvia Matrix */
.matrix-rain-char {
  position: absolute;
  color: #369d6d;
  font-size: 20px;
  font-family: 'hermann';
}








/* Titulo animado, subrayado de secciones */

.titulo-secciones {
  position: relative;
  display: inline-block;
  font-family: 'Hermann';
  font-weight: bold;
  font-style: normal;
  font-size: 1.9rem;

}

.underline {
  display: block;
  height: 2px;
  /* Ajusta el grosor de la línea */
  width: 0;
  background: #369d6d;
  /* Color de la línea */
  position: absolute;
  bottom: -5px;
  /* Ajusta la posición vertical de la línea */
  left: 0;
  transition: width 0.7s ease;
  /* Transición suave */
}

.animate-line .underline {
  width: 100%;
}

/* Colores de los links que redireccionan */

.link-color {
  color: #205c40;
  font-weight: 600;
  text-decoration: none;

}

.link-color:hover {
  color: white;
  /* Color del texto al pasar el mouse */
  background-color: #369d6d;
  /* Fondo al pasar el mouse */
  text-decoration: none;

}

/* BOTONES */

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 0;
  margin-bottom: 30px;

}

.link-boton {
  /* Colores de los botones */
  color: black;
  border-color: #205c40;
  background-color: transparent;
  width: 250px; /* ancho para todos los botones */
 

}

.link-boton:hover {
  color: white;
  /* Color del texto al pasar el mouse */
  background-color: #369d6d;
  /* Fondo al pasar el mouse */

}


/* scroll a secciones */
@keyframes scrollBackground {
  from {
    background-position-y: 0;
    /* Posición inicial de la imagen */
  }

  to {
    background-position-y: 100%;
    /* Posición final de la imagen */
  }
}

/* clase de los botones de INFORMES/CULTURA */
.cult{
  font-size: 1rem;
  width: 280px;
}



/* ESTILOS FOOTER 3 */

/* 360px x 640px */

footer {
  width: 100%;
  background-color: #222;
  font-weight: 100;
  color: white;
}

.logo-footer3 {
  display: block;
  margin: 0 auto;
  width: 14%;
  max-width: 130%;
  height: auto;

}

.direc {
  font-size: 0.9rem;
}

.email {
  font-size: 0.9rem;
}

/* Flexbox para mantener los elementos en una columna */
.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.nav-item {
  margin: 3px 0;
  /* Espaciado entre los elementos */
}


/* Media query para pantallas pequeñas */
@media only screen and (max-width: 640px) {

  .logo-footer3 {
    width: 60%;
    /* Aumenta el tamaño en pantallas pequeñas */
    max-width: 200px;
    /* Limita el tamaño máximo proporcionalmente */
  }
}




.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #205c40;
  border-radius: 10px;
  padding: 16px;
  background-image: url('../public/img/tramas10.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-width: 8rem;
  max-width: 16rem;
  margin: 0 auto;
  /* Center the container */
  height: 8rem;
}

/* Styling for the map box */
.map-box {
  width: 100%;
  height: 100%;
  /* Adjust the height as per your requirement */
}

/* Media query para pantallas medianas y grandes */
@media only screen and (min-width: 768px) {
  .map-container {
    max-width: 36rem;
    /* Ancho máximo mayor para pantallas medianas y grandes */
    min-width: 18rem;
    /* Ancho mínimo mayor para pantallas medianas y grandes */
    height: 12rem;
  }
}





/* HEADER 2 */

#header2 {
  width: 100%;

}

.header2-bg {
  width: 100%;
  height: 280px;
  object-fit: cover;


}

.header2-container {
  position: relative;
  /*   height: 280px;  */

}


.titular {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-family: 'hermann';
  font-weight: bolder;
  color: white;
  font-size: 1.5rem;
  /*   Contenedor transparente: */
  display: inline-block;
  background: rgba(0, 0, 0, 0.491);
  border-radius: 5px;
  padding: 20px 30px;
  /*   Para que los titulos no se dividan: */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */


}


/* SECCIONES 2: VER MAS Y PAGINAS-NAV */

.texto2-seccion {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: justify;
  margin-top: 45px;

}


.seccion2-container {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;

}

/* Esto mueve el padd y marg de la imagen...para tener en cuenta  */



.imagenSeccion {
  width: 50%;
  height: auto;
  object-fit: cover;
  clip-path: circle(50%);
  float: right;
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  shape-image-threshold: 0.1;
  shape-outside: circle(50% at 50% 50%);
  margin-left: 10px;
}




.titulo-secciones2 {
  text-align: center;
  font-family: 'Hermann';
  font-size: 1.4rem;
}



/* footer 4 */

.Firma {
  font-family: 'Hermann';
  font-weight: bold;
  color: /* #205c40 */ #369d6d ;
  font-size: 0.9rem;
  line-height: 1;
  margin-left: 16px;
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */

}
/* 
Estos dos puntos -page-container- abarcan las secciones 2, 
para que sea lo que tenga de contenido, siempre ubique el footer4 abajo, sin dejar aire: */
.seccion2-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la pantalla */
}

/* Contenido: desde subtitulo hasta botones, previo al footer4*/
.seccion2-wrap {
  flex: 1; /* Toma el espacio restante, empujando el footer hacia abajo */
}

/*  Queris para pantallas (secciones2) */

/* Pantallas medianas */

@media (min-width: 500px) {
    
  .outer-container {
    width: 70vw;
    height: 70vw;
    max-width: 45rem;
    max-height: 45rem;

  }


  .text-construct {
    font-size: clamp(1.8rem, 4vw, 2.2rem);
    margin-top:1rem;
  }

  .icon-construc {
    width: 60vw; /* Ajusta el tamaño del icono para pantallas medianas */
    max-width: 90rem;
  }

  .footer4{
    margin-top: 3rem;
    
   }

  
}

@media (min-width: 640px) {
   
  .outer-container {
    width: 60vw;
    height: 60vw;
    max-width: 45rem;
    max-height: 45rem;
  }


  .text-construct {
    font-size: clamp(1.8rem, 4vw, 2.2rem);
  }

  .icon-construc {
    width: 60vw; /* Ajusta el tamaño del icono para pantallas medianas */
    max-width: 90rem;
  }

  .cult{
    font-size: 1.1rem;
    width: 280px;
  }
  
}

@media (min-width: 768px) {
  .titular {
    font-size: 2rem;
    /* Tamaño de fuente para pantallas medianas */
  }

  .logoFooter4 {
    width: 130px;
    height: auto;
    
  }

  .Firma {
    font-size: 1rem;
  }

  .titulo-secciones2 {
    font-size: 1.7rem;
  }
  
  .outer-container {
    width: 60vw;
    height: 50vw;
    max-width: 45rem;
    max-height: 45rem;
  }


  .text-construct {
    font-size: clamp(1.8rem, 4vw, 2.2rem);
  }

  .icon-construc {
    width: 60vw; /* Ajusta el tamaño del icono para pantallas medianas */
    max-width: 90rem;
  }

  .cult{
    font-size: 1.2rem;
    width: 280px;
  }


}

/* Pantallas grandes */
@media (min-width: 992px) {
  .titular {
    font-size: 2.2rem;
    /* Tamaño de fuente para pantallas grandes */
  }

  .logoFooter4 {
    width: 138px;
    height: auto;
  }

  .Firma {
    font-size: 1.1rem;
  }

  .titulo-secciones2 {
    font-size: 1.8rem;
  }

  .outer-container {
    width: 45vw;
    height: 40vw;
    max-width: 40rem;
    max-height: 40rem;
  }

 
  .text-construct {
    font-size: clamp(2rem, 4vw, 2.1rem);
  }

  .icon-construc {
    width: 45vw; /* Ajusta el tamaño del icono para pantallas grandes */
    max-width: 100rem;
  }


}

/* Pantallas muy grandes */
@media (min-width: 1200px) {
  .titular {
    font-size: 2.5rem;
    /* Tamaño de fuente para pantallas muy grandes */
  }

  .imagenSeccion {
    width: 38%;
  }

  .logoFooter4 {
    width: 138px;
    height: auto;
  }

  .Firma {
    font-size: 1.1rem;
  }

  .titulo-secciones2 {
    font-size: 1.9rem;
  }
  .outer-container {
    width: 40vw;
    height: 30vw;
    max-width: 35rem;
    max-height: 35rem;
  }

  .text-construct {
    font-size: clamp(1.9rem, 4vw, 2rem);
  }

  .icon-construc {
    width: 35vw; /* Ajusta el tamaño del icono para pantallas grandes */
    max-width: 100rem;
  }

 
}

@media (min-width: 1400px) {
  .outer-container {
    width: 45vw;
    height: 29vw;
    max-width: 35rem;
    max-height: 35rem;
  }

  .text-construct {
    font-size: clamp(1.9rem, 4vw, 2rem);
  }

  .icon-construc {
    width: 36vw; /* Ajusta el tamaño del icono para pantallas grandes */
    max-width: 100rem;

}

.cult{
  font-size: 1.2rem;
}

}

@media (min-width: 1600px) {
  .cult{
    font-size: 1.2rem;
  }

}

@media (min-width:1920px){

  .outer-container {
    width: 40vw;
    height: 24vw;
    max-width: 35rem;
    max-height: 35rem;
  }
  .icon-construc {
    width: 30vw; /* Ajusta el tamaño del icono para pantallas grandes */
    max-width: 100rem;

}

.cult{
  font-size: 1.2rem;
  
}

}


/* ESTILOS ORGANIGRAMA
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --level-1: #369d6d;
  --level-2: #ffed9f;
  --level-3: #a1cfff;
  --level-4: #f9765d;
  --black: black;
}

.org-container * {
 
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 1.3rem;
}

.org-container ol {
  list-style: none;
}

/* body {
  margin: 50px 0 100px;
   ESTO APLICABA MARGEN ARRIBA Y ABAJO PEOR LO SAQUE PORQUE BODY ES GLOBAL.
  PUSE MARGEN CON BR-CUALQUIER COSA AGREGAR CONTENEDOR QUE ENGLOBE TODO CON OTRO NOMBRE

} */

.org-container {
  max-width: 1350px;
  padding: 0 10px;
  margin: 50px auto 100px;
  text-align: center;
  font-family:'Hermann';
  
}

.rectangle {
  position: relative;
  padding: 14px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;

} 
.noPoint {
  cursor: default;
}/*  para los rectangles que no se despliegan, no colocamos pointer */


/* LEVEL-1 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-1 {
  width: 50%;
  margin: 0 auto 40px;
  background: var(--level-1);
}

/* LEVEL-2 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-2-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.level-2-wrapper li {
  position: relative;
}

.level-2 {
  width: 105%;
  margin: 0 auto 20px; 
  background: var(--level-2);

}


/* LEVEL-3 STYLES  managers
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-3-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-column-gap: 20px;
  width: 85%; /* ancho rectángulo manager y person */
  margin: 0 auto;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
  height: 0; /* Oculto por defecto */
  opacity: 0; /* Oculto por defecto */
}

.level-3-wrapper.expanded {
  height: auto; /* Ajusta la altura según el contenido */
  opacity: 1;
}


.level-3 {
  margin-bottom: 20px;
  background: var(--level-3);
  height: 60px;
}

/* LEVEL-4 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-4-wrapper {
  position: relative;
  width: 85%;
   margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
  height: 0; /* Oculto por defecto */
  opacity: 0; /* Oculto por defecto */
  
}

.level-4-wrapper.expanded {
  height: auto; /* Ajusta la altura según el contenido */
  opacity: 1;
}

.level-4-wrapper li + li {
  margin-top: 20px;
}

.level-4 {
  font-weight: normal;
  margin-bottom: 14px;
  background: var(--level-4);
  height: 55px;
  
}

/* TEXTOS ORGANIGRAMA */

.caja-texto{
  display: flex;
  flex-direction: column; /* Para colocar el texto en columnas */
  align-items: center;
  justify-content: center;  
  position: relative; /* Necesario para el posicionamiento absoluto del símbolo */
}

/* ---TEXTOS EN BOX VERDE, INTENDENTE---- */

.org-title{
  font-size: 1.5rem; 
  font-weight:bolder;
  font-family: 'hermann';
}

.org-name{
  font-size: 1.3rem;
  font-family: 'Hermann';
 
}

.expand-icon {
  position: absolute;
  bottom: 5px; /* Ajusta el espaciado desde el borde inferior */
  right: 5px; /* Ajusta el espaciado desde el borde derecho */
  font-size: 1.2rem; /* Ajusta el tamaño del símbolo */
  cursor: pointer;
  background: none; /* Elimina el fondo del símbolo */
  border: none; /* Elimina el borde del símbolo */
  padding: 0; /* Elimina el relleno */
  color:red;
}

/* .info-icon {
  position: absolute;
  bottom: 12px; 
  right: 2px; 
  font-size: 0.9rem; 
  color: green; 
  cursor: pointer; 
} */

.info-icon2 {
  position: absolute;
  bottom: 5px; /* Ajusta el espaciado desde el borde inferior */
  right: 5px; /* Ajusta el espaciado desde el borde derecho, asegurando que no se superponga con el símbolo expandible */
  font-size: 0.9rem; /* Ajusta el tamaño del ícono */
  color: black; /* Cambia el color si es necesario */
  cursor: pointer; /* Cambia el cursor al pasar sobre el ícono */
}

/* ---TEXTOS EN BOX AMARILLOS---- */

.org-title2{
  font-size: 1.1rem; 
  font-weight:bolder;
  font-family: 'hermann';
}

.org-name2{
  font-size: 1.1rem;
  font-family: 'Hermann';
 
}

/* ---TEXTOS EN BOX CELESTES---- */

.org-title3{
  font-size: 1rem; 
  font-weight:bolder;
  font-family: 'hermann';
}

.org-name3{
  font-size: 1rem;
  font-family: 'Hermann';
 
}

/* ---TEXTOS EN BOX NARANJAS---- */

.org-title4{

  font-size: 0.9rem; 
  font-weight:bolder;
  font-family: 'hermann';

}

.org-name4{
  font-size: 0.9rem;
  font-family: 'Hermann';
 
}

/* BOTON SCROLL TOP */
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 1000;
}

.scroll-to-top-button {
  background-color: #205c40;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  color: white;
  cursor: pointer;
  padding:5px;
}

.scroll-to-top-button:hover {
  background-color: #369d6d;
}


.scroll-to-top i {
  font-size: 24px;
} 


/* boton scroll top organigrama */
.scrollable {
  height: 450px; /* o el valor que prefieras */
  overflow-y: scroll;
  position: relative;
}

.scroll-to-top-btn {
  position: absolute;
  bottom: 10px;
  right: 28px;
  padding: 4px;
  background-color: #205c40;
  color: white;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size: 20px;
  
}

.scroll-to-top-btn:hover {
  background-color: #369d6d;
}

/* 
scroll Org */
/* .scroll-org {
  position: fixed;
  bottom: 30px;
  right: 20px;
  background-color: #205c40; 
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-org:hover {
  background-color: #369d6d;
}

.scroll-org i {
  font-size: 18px;
} */






/* mediaquerys
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 1200px) {
  .rectangle {
    padding: 20px 10px;
  }

  .level-1,
  .level-2 {
    width: 100%;
  }

  .level-1 {
    margin-bottom: 20px;
  }

  .level-1::before,
  .level-2-wrapper > li::before {
    display: none;
  }
  
  .level-2-wrapper,
  .level-2-wrapper::after,
  .level-2::after {
    display: block;
  }

  .level-2-wrapper {
    width: 90%;
    margin-left: 10%;
  }

  .level-2-wrapper::before {
    left: -20px;
    width: 2px;
    height: calc(100% + 40px);
  }

  .level-2-wrapper > li:not(:first-child) {
    margin-top: 50px;
  }
}

/* scroll para organigrama desde pantallas 768 */
@media screen and (min-width: 768px) {
.scrollable {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 10px; 
}


}

@media screen and (min-width: 1201px) {

  .scroll-to-top-btn {
      left: 50%;
    transform: translateX(-50%);
  
  }
  
  }

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  /* Estilos específicos para pantallas entre 1201px y 1300px de ancho */
  .org-title3{
    font-size: 0.9rem; 
  }
  
  .org-name3{
    font-size: 0.9rem; 
   
  }

  .org-title4{

    font-size: 0.8rem; 
    font-weight:bolder;
    font-family: 'hermann';
  
  }
  
  .org-name4{
    font-size: 0.8rem;
    font-family: 'Hermann';
   
  }

  .level-4 {
    margin-bottom: 14px;
    background: var(--level-4);
    height: 60px;
    
  }
}

@media screen and (min-width: 1501px) {

  
  .level-4 {
    margin-bottom: 14px;
    background: var(--level-4);
    height: 65px;
    
  }
}





/* PDFs-DATOS ECO- */

.pdf-container{
   width: 17.2rem;
    height:16rem;
    margin: 10px;
    border: 1px solid #205c40;

}


.cardBody{
  font-family: 'Hermann';


}

.pdf-viewer {
  height: 8.8rem; /* Limita la altura de la previsualización del PDF */
  overflow-y: auto; /* Permite que el contenido sea desplazable */
  margin-bottom: 12px;

}



.pdfTitulo{
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* botones para pdfs */

.button-container2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Para asegurarnos de que el contenedor ocupe todo el ancho */
  gap: 2px;
  
 
}


.link-boton2 {
  /* Colores de los botones */
  color: white;
  border-color: #369d6d;
  background-color: #369d6d;
  width: 120px; /* ancho para todos los botones */
  height: 33px;
  font-size: 15px;

  


}

.link-boton2:hover {
  color: white;
  /* Color del texto al pasar el mouse */
  background-color: #369d6d;
  /* Fondo al pasar el mouse */
  border: #369d6d;


}

/* Botones para /datos-guias Gobernanza de datos  */

.link-boton3 {
  /* Colores de los botones */
  color: black;
  border-color: #205c40;
  background-color: transparent;
  width: 280px; /* ancho para todos los botones */
  height: 80px;
  font-size: 1.2rem;

}

.link-boton3:hover {
  color: white;
  /* Color del texto al pasar el mouse */
  background-color: #369d6d;
  /* Fondo al pasar el mouse */

}

/* 
PDFS INTERNOS

FORMULARIO INFO PUBLICA. */
.pdf-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.pdf-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.pdf-wrapper iframe {
  width: 90%;
  height: 90vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}



/* HTMLS INCRUSTADOS */

.iframe-container{
  margin-top: 0px;
  margin-bottom: -90px;
  width: 100%; 
 
  
}

.titular-html {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-family: 'hermann';
  font-weight: bolder;
  color: white;
  font-size: 1.5rem;
  /*   Contenedor transparente: */
  display: inline-block;
  background: rgba(0, 0, 0, 0.491);
  border-radius: 5px;
  padding: 20px 30px;
  /*   Para que los titulos no se dividan: */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */


}

.header-html{
  height: 200px !important;
}

/* media querys para iframe en pantallas móviles */
@media (max-width: 768px) { 
  .iframe-container {
      width: 95%; 
  }
  .titular-html {
      font-size: 1.2rem;
  }
}